/* @font-face {
  font-family: 'LEMON-MILK';
  src: url(../public/assets/fonts/LEMONMILK-Regular.otf);
}

@font-face {
  font-family: 'Century-Gothic';
  src: url(../public/assets/fonts/CenturyGothic.ttf);
} */

#root {
  height: 100%;
}


html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  font-family: "Goldman", sans-serif;
  background-color: #12140D;
  overflow-x: hidden;
  color: #FCFCCD;
  /* letter-spacing: -1px; */
}


a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}


.fs-12 {
  font-size: 12px !important;
}

.fs-48 {
  font-size: 48px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.danger-text {
  color: #9EFF00 !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}


p {
  color: #FCFCCD;
  font-size: 18px;
  line-height: normal;
}

h2 {
  font-size: 48px;
  font-weight: 600;
}

/* Header  */

.navbar {
  /* height: 85px; */
  position: fixed;
  width: 100%;
  z-index: 111;
  top: 0;
  /* background: linear-gradient(122.96deg, #FEF8F4 30.33%, #FFFFFF 119.91%); */
}

.light-logo {
  height: 40px;
}

.bg-pink {
  /* background: #498FF8; */
  backdrop-filter: blur(10px);
}

.navbar .nav-link {
  font-weight: 400;
  font-size: 16px;
  color: #FCFCCD;
  margin: 0 5px;
  position: relative;
  transition: all .2s ease-in-out;
  text-align: center;
}


.navbar .nav-link:hover {
  color: #9EFF00 !important;
  transition: all .2s ease-in-out;
}

.navbar .nav-link img {
  height: 22px;
  transition: all .2s ease-in-out;

}

.navbar .nav-link:hover img {
  transform: translate(0px, -10px);
  transition: all .2s ease-in-out;
}


.navbar-nav {
  position: relative;
}

/* .navbar-nav::after {
  content: "";
  background: #2AE8DC;
  height: 2px;
  width: 100%;
  bottom: -5px;
  left: 0;
  position: absolute;
} */

.navbar-collapse.collapsing {
  transition: none !important;
}


/* Banner */

.top-banner {
  background: rgba(158, 255, 0, .3);
  padding: 12px 0;
  text-align: center;
  font-size: 16px;
  width: 100%;
  color: white;
}

.top-banner a {
  color: #9EFF00;
}

.bg-grad {
  background-image: url(../public/assets/bg1.png);
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  padding: 150px 0px 30px 0px;
}

.banner h1 {
  font-size: 46px;
  font-weight: 600;
}

.blast {
  padding-top: 10px;
}

.banner p {
  font-size: 18px;
}

.blast img {
  height: 35px;
}


.scroll-img {
  height: 45px;
}

.logo-light {
  height: 40;
}

.primary-btn {
  color: #9EFF00;
  border: 0px solid #1A394D;
  padding: 20px 25px;
  font-size: 18px;
  font-weight: 700;
  height: 70px;
  display: inline-flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  background: url(../public/assets/prim.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all .3s ease-in-out;
}


.primary-btn:hover {
  transform: translate(0px, -5px);
  transition: all .3s ease-in-out;
}


.secondary-btn {
  color: #9EFF00;
  border: 0px solid #1A394D;
  padding: 20px 48px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  /* height: 46px; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: url(../public/assets/sec.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all .3s ease-in-out;
}


.secondary-btn:hover {
  transform: translate(0px, -5px);
  transition: all .3s ease-in-out;
}

.secondary-btn svg {
  width: 80px;
}

.secondary-btn:hover svg path {
  fill: #9EFF00;
}


/* .transition-all {
  transition-duration: .15s !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
} */

.dark-logo {
  display: none;
}

.dark .light-logo {
  display: none;
}

.dark .dark-logo {
  display: inline-block;
}

.z-1 {
  z-index: 1;
}


@media (max-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #2B1D11;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar {
    /* position: fixed; */
    z-index: 1111;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }

  .navbar .nav-link {
    color: #FCFCCD;
  }

  .navbar-collapse {
    position: fixed;
    left: -400px;
    opacity: 0;
    transition: all .3s linear;
    display: block !important;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    opacity: 1;
    width: 100%;
    left: 0;
    height: 100vh;
    background: rgb(31 29 29 / 95%);
    backdrop-filter: blur(10px);
    z-index: 111;
    left: 0px;
    transition: all .3s linear;
    display: block !important;
  }


  .cross-btn {
    font-size: 24px;
    color: #9EFF00 !important;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    align-items: flex-start !important;
  }

  .navbar-brand img {
    height: 28px;
  }

  /* .navbar-nav {
    gap: 20px;
    flex-direction: row;
    margin-top: 25px;
  } */

  .navbar-nav::after {
    content: none;
  }

}

@media (min-width: 992px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1300px;
  }
}


.bg-2 {
  padding: 70px 0 50px 0;
}

.bg-2 p {
  font-size: 26px;
}

.bg-2 h3 {
  position: relative;
  font-size: 58px;
  margin-bottom: 15px;
  padding-bottom: 14px;
}

.bg-2 h3::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 120px;
  background: #fcfccd46;

}

.mt-40 {
  margin-top: -40px;
}

.bg-3 {
  /* background-image: url(../public/assets/in.png); */
  background-size: 55%;
  background-position: bottom right;
  background-repeat: no-repeat;
}

.inner-bg {
  background-image: url(../public/assets/bg-3.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 30px;
}

.bg-3 h3 {
  font-size: 32px;
  font-weight: bold;
}

.featured {
  padding: 50px 0;
}


.feature-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: all .3s ease-in-out;
}

.feature-img {
  max-height: 200px;
}

.feature-img img {
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  transition: all .3s ease-in-out;
}

.feature-card:hover img {
  filter: drop-shadow(0.1em 0.1em 0.6em #9EFF00);
  transition: all .3s ease-in-out;
}

.feature-detail {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  gap: 10px;
}

.feature-card p {
  margin-bottom: 0;
}

.article {
  padding: 50px 0;
}

.partners {
  padding-top: 50px;
}

.partners h3,
.powered h3 {
  font-size: 30px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
  color: #9EFF00;
}

.partners img {
  transition: all .2s ease-in-out;
  padding-bottom: 10px;
}

.partners img:hover {
  transform: scale(1.125);
  transition: all .2s ease-in-out;
}

footer h4 {
  font-size: 18px;
  color: #9EFF00;
  font-weight: 600;
  margin-bottom: 20px;
}

footer ul li {
  font-size: 18px;
  margin-bottom: 10px;
}

.com-card {
  padding: 10px;
  border-top: 1px solid #fcfccd46;
  border-bottom: 1px solid #fcfccd46;
}

.community {
  padding: 30px 0;

}

.com-card .active img {
  height: 40px;
}

.featured h3,
.article h3 {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 12px;
}

footer li a {
  transition: all .2s ease-in-out;
}

footer li a:hover {
  color: #9EFF00;
  transition: all .2s ease-in-out;
}

.com-card a {
  display: flex;
  position: relative;
  padding: 20px;
  justify-content: center;
  max-height: 68px;
  transition: all .2s ease-in-out;
}

.com-card a::after {
  content: "";
  position: absolute;
  width: 0%;
  left: 0;
  right: 0;
  bottom: -15px;
  height: 8px;
  transition: all .2s ease-in-out;
}

.com-card a:hover::after,
.com-card a.active::after {
  background: #9EFF00;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 0px 14px 0px #9EFF00E5;
  transition: all .2s ease-in-out;
}

.sub-footer {
  gap: 20px;
}

.sub-footer p {
  font-size: 16px;
}

.sub-footer .footer-link {
  color: #9EFF00;
  font-size: 16px;
}

.footer-ic,
.prime-btn {
  transition: all .2s ease-in-out;
}

.footer-ic:hover,
.prime-btn:hover {
  transform: translate(0px, -5px);
  transition: all .2s ease-in-out;

}

.col-20 {
  flex: 1 0 17%;
  text-align: center;
}

#campaign a:hover {
  color: #9EFF00;
}

.soon {
  position: absolute;
  right: 20px;
  bottom: 10px;
  font-size: 12px;
  color: #9EFF00;
}

@media(max-width: 1200px) {
  .banner h1 {
    font-size: 42px;
  }

  .banner h1 p {
    font-size: 22px;
  }
}

.audit {
  border-top: 1px solid #fcfccd46;
  padding-top: 30px;
}

#partner .container {
  border-bottom: 1px solid #fcfccd46;

}

.audit h3 {
  font-size: 30px;
  font-weight: bold;
  position: relative;
  color: #9EFF00;
  white-space: nowrap;
  margin-bottom: 0;
}

.prime-btn img {
  height: 70px;
}


.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  font-size: 70px;
  margin-bottom: 50px;
  line-height: 46px;
  padding-top: 50px;
}

.sec {
  width: 110px;
  text-align: center;
}







.countdown-timer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-bottom: 50px;
  padding-top: 50px;
}

.time-unit {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.digit {
  font-size: 64px;
  margin: 0 2px;
  border-radius: 5px;
  background: #9EFF00;
  color: black;
  padding: 10px;
  line-height: 36px;
  display: inline-block;
  width: 62px;
  box-shadow: 0px 0px 10px 0px #9EFF00;
}

.label {
  margin-top: 5px;
  font-size: 1rem;
  text-transform: uppercase;
}

.video-container {
  position: relative;
  border-radius: 30px;
}

.video-container video {
  border-radius: 30px;
}

.vid-btn {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  justify-content: center;
  align-items: center;
  top: 0;
}

.video-control:not(.playing) .video-control-pause,
.video-control.playing .video-control-play {
  display: none;
}

.video-control {
  background: transparent;
}

.no-play .vid-btn {
  height: 0;
  width: 0;
  display: none;
}

.inner-bg img {
  height: 32px;
}

.inner-bg h3 {
  flex: 1;
}

@media(max-width: 767px) {
  .vid-btn {
    display: none;
  }
  .blast img {
    height: 22px;
  }

  .video-control-symbol img {
    height: 40px;
  }

  .prime-btn img {
    height: 55px;
  }

  .powered img {
    height: 50px;
  }

  .audit h3 {
    font-size: 18px;
  }

  footer ul li {
    margin-bottom: 2px;
  }

  .top-banner {
    font-size: 10px;
    padding: 8px 0;
    margin-bottom: 4px;
  }

  .w-sm-100 {
    width: 100%;
  }

  .bg-3 {
    background-image: url(../public/assets/bg-sm.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 20px;
    padding-top: 50px;
  }

  .blast {
    height: 32px;
  }

  .inner-bg {
    background-image: none;
    padding: 0px;
  }

  .bg-3 h3 {
    font-size: 22px;
  }

  .bg-3 p {
    font-size: 14px;
  }

  .banner h1 {
    font-size: 32px;
  }

  .fs-18 {
    font-size: 12px !important;
  }

  .partners {
    padding-bottom: 30px;
  }

  .article {
    padding: 20px 0;
  }

  .partners h3,
  .powered h3,
  .featured h3,
  .article h3 {
    font-size: 22px;
  }

  .partners h3::after {
    bottom: 38px;
    width: 55%;
  }

  .banner p {
    margin-bottom: 0;
    font-size: 15px;
  }

  .nine h3::after {
    width: 70px !important;

  }

  .countdown-timer {
    gap: 10px;
    padding-top: 30px;
    margin-bottom: 30px;
  }

  .digit {
    font-size: 36px;
    border-radius: 3px;
    padding: 2px 0px;
    line-height: 30px;
    width: 34px;
  }

  .label {
    font-size: .8rem;
  }

  .bg-grad {
    padding: 100px 0px 0px 0px;
    background-image: url(../public/assets/bg1-sm.png);
  }

  .feature-card {
    gap: 10px;
  }

  .nep-logo {
    font-size: 52px;
  }

  .bg-2 h3::after {
    width: 130px;
  }

  .bg-2 {
    padding: 30px 0 30px 0;
  }

  .bg-2 h3 {
    margin-bottom: 10px;
    padding-bottom: 8px;
  }

  .bg-2 p {
    font-size: 18px;
  }

  h2 {
    font-size: 32px;
  }

  .mt-40 {
    margin-top: 0px;
  }

  .featured {
    padding: 30px 0;
  }

  .primary-btn,
  .secondary-btn {
    font-size: 16px;
  }

  .secondary-btn svg {
    width: 80px;
  }

  p {
    font-size: 16px;
  }

  .sub-footer {
    gap: 10px;
  }

  .sub-footer .footer-link {
    font-size: 14px;
  }

  .sub-footer p {
    font-size: 14px;
  }

  footer ul li {
    font-size: 14px;
  }

  .com-card a::after {
    height: 4px;
  }

  .com-card a:hover::after,
  .com-card a.active::after {
    width: 85%;
  }

}